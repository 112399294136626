angular.module('app').controller('ActivitiesPageController',['$scope','$uibModal','$filter','$sce','$q','$window','user','tools','modelstore','parameters','config','webservice',
                                            function($scope,$uibModal,$filter,$sce,$q,$window,user,tools,modelstore,parameters,config,webservice) {
  $scope.$window=$window;
  var model=$scope.model;
  var script='public/reservationC';
  $window.scrollTo(0, 0);

  $scope.getFormbuttons = function() {
    return $scope.getFcaFormbuttons(model.fca);
  };

  $scope.isSelectionFormbuttonsPage = function() {
    return model.fca===$scope.ffo+'***';
  };

  $scope.orderByAvailability = function (formbutton) {
    return formbutton.available!==true;
  };

  $scope.addButtonToShortTermBasket = function(formbutton) {
    var packId='pack'+formbutton.id;
    if(formbutton.singlequantity==='1' && angular.isUndefined(model.formbuttoncomponents[packId])) {
      modelstore.addRecord('formbuttoncomponents',{
        'id':packId,
        'formbutton':formbutton.id,
        'sort':'0',
        'labelen':formbutton.labelen,
        'labelnl':formbutton.labelnl,
        'labelfr':formbutton.labelfr,
        'quantitylabelen':formbutton.quantitylabelen,
        'quantitylabelnl':formbutton.quantitylabelnl,
        'quantitylabelfr':formbutton.quantitylabelfr,
        'param1':'PA',
        'param2':'PAC',
        'param3':'',
        'dayoffset':'0',
        'hrs':'',
        'quantitymultiplier':'FIXED',
        'quantity':'0',
        'minpackquantity':formbutton.minpackquantity,
        'adultcountbehaviour':formbutton.adultcountbehaviour,
        'adultcount':formbutton.adultcount,
        'childcountbehaviour':formbutton.childcountbehaviour,
        'childcount':formbutton.childcount,
        'heatmap':'0',
        'unitprice':'0'
      },model);
    }
    $scope.shortTermBasket=$scope.shortTermBasket.concat($filter('orderBy')(tools.join(formbutton,'formbuttoncomponents',model),$scope.sort).map(function(x) {
      return {'formbuttoncomponent':x.id,'created':false};
    }));
  };

  $scope.filterAvailableFormbuttons = function(formbutton) {
    var result;
    var childFormbuttonrelations=tools.match(model.formbuttonrelations,{'parent':formbutton.id});
    if(tools.getLength(childFormbuttonrelations)>0) {
      result=tools.getLength(childFormbuttonrelations.map(function(formbuttonrelation) {
        return model.formbuttons[formbuttonrelation.child];
      }).filter($scope.filterAvailableFormbuttons))>0;
    } else {
      result=formbutton.available && $scope.filterOnDaydurationAndInterval(formbutton);
    }
    return result;
  };

  $scope.modifyDatesForAccommodation = function() {
    model.multipleDays='1';
    tools.redirect('dates');
  };

  $scope.selectButton = function(formbutton) {
    if(formbutton.available) {
      if(formbutton.pricekey!=='') {
        previousFormbutton=formbutton;
      }
      if(formbutton.clearbasket==='1') {
        $scope.shortTermBasket=[];
      }
      $scope.addButtonToShortTermBasket(formbutton);
      $scope.selectedFormbuttonrelations=tools.match(model.formbuttonrelations,{'parent':formbutton.id}).filter(function(formbuttonrelation) {
        return $scope.filterAvailableFormbuttons(model.formbuttons[formbuttonrelation.child]);
      });
      var selectedFormbuttons=$scope.selectedFormbuttonrelations.map(function(formbuttonrelation) {
        return model.formbuttons[formbuttonrelation.child];
      });
      var availableFormbuttons=selectedFormbuttons.filter($scope.filterAvailableFormbuttons);
      if(availableFormbuttons.length>1) {
        $scope.dismissModal();
        $scope.templates=tools.inverseJoin(selectedFormbuttons,'templates','template',model).reduce(function(acc,cur) {
          acc[cur.id]=tools.clone(cur);
          return acc;
        },{});
        var i,j,template,previousFormbuttoncomponents,previousFormbuttoncomponent,previousDescription,formbuttonsWithThisTemplate,replacedRenderedTemplate;
        if(previousFormbutton!==null) {
          previousFormbuttoncomponents=tools.join(previousFormbutton,'formbuttoncomponents',model);
          if(previousFormbuttoncomponents.length>0) {
            previousFormbuttoncomponent=previousFormbuttoncomponents[0];
            for(i in $scope.templates) {
              template=$scope.templates[i];
              for(j in langs) {
                if(previousFormbuttoncomponent.param1==='AC') {
                  previousDescription=parameters.ACT[previousFormbuttoncomponent.param2.substr(0,3)]['label'+langs[j]];
                  if(previousFormbuttoncomponent.param2.substr(3,3)!=='***' && parameters.FRM[previousFormbuttoncomponent.param2].field3==='1') {
                    previousDescription+=' '+parameters.FRM[previousFormbuttoncomponent.param2]['label'+langs[j]];
                  }
                } else if(previousFormbuttoncomponent.param1==='ME') {
                  previousDescription=parameters.DSH[previousFormbuttoncomponent.param2]['label'+langs[j]];
                } else if(previousFormbuttoncomponent.param1==='HS') {
                  if(previousFormbuttoncomponent.param2.substr(3,3)==='***') {
                    previousDescription=parameters.HTL[previousFormbuttoncomponent.param2.substr(0,3)]['label'+langs[j]];
                  } else {
                    previousDescription=parameters.HSC[previousFormbuttoncomponent.param2]['label'+langs[j]];
                  }
                } else if(previousFormbuttoncomponent.param1==='PR') {
                  previousDescription=parameters.PRD[previousFormbuttoncomponent.param2]['label'+langs[j]];
                }
                template['rendered'+langs[j]]=template['rendered'+langs[j]].replace(/%previous%/g,previousDescription);
                template['rendered'+langs[j]]=template['rendered'+langs[j]].replace(/previous/g,previousFormbutton.pricekey);
              }
            }
          }
        }
        for(i in $scope.templates) {
          template=$scope.templates[i];
          formbuttonsWithThisTemplate=tools.match(model.formbuttons,{'template':template.id});
          for(j in langs) {
            replacedRenderedTemplate=tools.replaceAll(model.priceReplace,template['rendered'+langs[j]]);
            if(formbuttonsWithThisTemplate.length===1 && formbuttonsWithThisTemplate[0].singlequantity==='1') {
              replacedRenderedTemplate=replacedRenderedTemplate.replace('%P=PA%',model.packPrices[formbuttonsWithThisTemplate[0].id]);
            }
            template['trustedRendered'+langs[j]]=$sce.trustAsHtml(replacedRenderedTemplate);
          }
        }
        $scope.modal=$uibModal.open({
          'templateUrl':'app/views/submenuModal.html',
          'scope':$scope,
          'size':'lg',
          'backdrop':'static',
          'keyboard':false
        });
        $scope.modal.result.then(function(nextFormbutton) {
          $scope.modal=null;
          $scope.selectButton(nextFormbutton);
        },function() {
          $scope.modal=null;
          $scope.shortTermBasket=[];
        });
      } else if(availableFormbuttons.length===1) {
        $scope.selectButton(availableFormbuttons[0]);
      } else {
        previousFormbutton=null;
        $scope.openDetailsModal();
      }
    }
  };

  $scope.editLongTermBasketContent = function(longTermBasketContent) {
    $scope.shortTermBasket=tools.clone(longTermBasketContent);
    var toGTM=[];
    var items=$scope.getItems($scope.shortTermBasket);
    for(var id in items) {
      toGTM.push(model.items[items[id].id]);
    }
    $scope.pushGTMRemoveFromCart(toGTM);
    $scope.openDetailsModal('edit');
  };

  $scope.trustAsHtml = function() {
    $scope.trustLabelsAsHtml('activities',model.formbuttoncomponents);
  };

  $scope.openDetailsModal = function(type) {
    var result;
    if($scope.shortTermBasket.length>0) {
      if(type==='edit') {
        var collections=$scope.getEventsAndProducts($scope.shortTermBasket,model);
        var i,collectionName,collection,record;
        model.backupCollections={};
        for(collectionName in collections) {
          model.backupCollections[collectionName]={};
          collection=collections[collectionName];
          for(i in collection) {
            record=collection[i];
            model.backupCollections[collectionName][record.id]=tools.clone(record);
          }
        }
      }
      $scope.dismissModal();
      $scope.modal=$uibModal.open({
        'templateUrl':'app/views/detailsModal.html',
        'scope':$scope,
        'size':$scope.isSiteMode?'md':'lg',
        'backdrop':'static',
        'keyboard':false
      });
      result=$scope.modal.result.then(function(response) {
        $scope.modal=null;
        $scope.shortTermBasket=[];
        $scope.updateRecordsFromLongTermBasket(response.shortTermBasket);
        $scope.addRecordsFromShortTermBasket(response.shortTermBasket,response.collections,true);
        $scope.trustAsHtml();
        return response;
      },function() {
        $scope.modal=null;
        $scope.shortTermBasket=[];
        var i,collectionName,collection,record;
        if(type==='edit') {
          for(collectionName in model.backupCollections) {
            collection=model.backupCollections[collectionName];
            for(i in collection) {
              record=collection[i];
              modelstore.addRecord(collectionName,record,model);
            }
            model.backupCollections[collectionName]={};
          }
        }
      });
    } else {
      result=$q.reject();
    }
    return result;
  };

  $scope.openBuyVoucherModal = function() {
    $scope.shortTermBasket=[{'formbuttoncomponent':'voucher'}];
    $scope.openDetailsModal().then(function(response) {
      var originalFormbuttoncomponentReference=response.shortTermBasket[0];
      var originalProduct=model.products[originalFormbuttoncomponentReference.record];
      originalProduct.isNotShadow=false;
      var originalItem=model.items[originalProduct.item];
      var item,product,formbuttoncomponentReference;
      for(var k=1;k<originalProduct.quantity;k++) {
        item={
          'id':tools.getMinIdMinusOne(tools.toArray(model.items)),
          'visit':'-1',
          'reductionamount':'0',
          'grossunitprice':1,
          'netunitprice':1,
          'quantity':originalItem.quantity
        };
        modelstore.addRecord('items',item,model);
        product={
          'id':tools.getMinIdMinusOne(tools.toArray(model.products)),
          'visit':'-1',
          'item':item.id,
          'prd':'VOU'
        };
        modelstore.addRecord('products',product,model);
        formbuttoncomponentReference={
          'formbuttoncomponent':originalFormbuttoncomponentReference.formbuttoncomponent,
          'record':product.id
        };
        model.longTermBasket.push([formbuttoncomponentReference]);
      }
      model.longTermBasket.push(response.shortTermBasket);
      var params=$scope.createReservationParams([],[],{},false);
      params.visit.campaign='';
      webservice.call('calculatePrices',params,script,model);
    });
  };

  $scope.continue = function() {
    if($scope.isKioskMode) {
      $scope.termsAndConditionsAreAccepted().then(function() {
        $scope.openVoucherModal().then(function(voucherCodes) {
          webservice.exclusiveCall('createToPayCtep',$scope.createReservationParams(voucherCodes,[],{},true),script,model).then(function(response) {
            model.ctepVisit=response.visit;
            if(response.method==='ctep') {
              model.ctepVisitAmount=response.amount;
              tools.redirect('paymentInstructions');
            } else if(response.method==='voucher') {
              tools.redirect('kioskFinished');
            }
          });
        });
      });
    } else if($scope.isSiteMode) {
      if(model.currentStepIndex>=tools.getLength($scope.getFilteredFca())) {
        $scope.openParkingQuestionModal().then(function() {
          $scope.redirectToCredentials();
        });
      } else {
        $scope.incrementCurrentStepIndex();
      }
    }
  };

  $scope.loaded.then(function() {
    if(angular.isUndefined(model.currentStepIndex)) {
      $scope.setCurrentStepIndexToMin();
    }
    if(model.currentStepIndex === 0) {
      $scope.incrementCurrentStepIndex();
    } else if(model.currentStepIndex === tools.getLength($scope.getFilteredFca()) + 1) {
      $scope.decrementCurrentStepIndex();
    }
  });

  model.page='activities';
  $scope.shortTermBasket=[];
  model.backupCollections={};
  model.backupShortTermBasket=[];
  var langs=config.langs;
  $scope.$on('loaded',$scope.trustAsHtml);
  $scope.trustAsHtml();
  var previousFormbutton=null;
  $scope.breakpointSm=801;
  $scope.dropdownIsOpen=true;
  $scope.itemsPerPage=12;
  $scope.page=1;
}]);
